import React from 'react'
import info from '../info.json'
import ReactModal from 'react-modal';
import '../App.css'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";




function Referral() {
    ReactModal.setAppElement('#root');
    const { web3g, pending, error } = useSelector((state) => state.wallet)
    const { pStats, pspending, pserror } = useSelector((state) => state.presale)



    const customStyles = {
        content: {
            backgroundColor: '#41015D',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            height: '45vh',
            borderRadius: '15px',
            color: '#E7B655',
            border: '2px solid black',
            transition: '5s'
        },
    };

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setIsOpen(false);
    }

    function copyToClip() {
        navigator.clipboard.writeText(window.location.protocol + '//' + window.location.host + "?ref=" + web3g.walletAddress);
        console.log(window.location)
        toast.success("Link Copied", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    }

    return (
        <>
            <div className="ref text-center mt-2">
                <h3>Click below to earn {info.tokenName}</h3>
                <img src="./assets/reward.gif" width={120} className="refGif" alt="" onClick={openModal} />
            </div>
            <ReactModal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="modalStyles">
                    <div className='modalClose' onClick={closeModal}>x</div>
                    <h5 className="text-center mt-3">Copy and Share Your Referral Code To Earn Tokens</h5>
                    <div className="inputWrap">
                        <input readOnly className='text-center refInput' value={web3g.connected ? window.location.protocol + '//' + window.location.host + "?ref=" + web3g.walletAddress : "Please Connect Your Wallet To Get Referral Link"} onClick={() => web3g.connected && copyToClip()} />
                    </div>
                    <h5 className="text-center mt-1">Click Link To Copy</h5>
                    <h5 className="text-center mt-1">
                        Each buy you referred you will earn %{pStats.refpercent} Gelite instantly.
                    </h5>

                </div>
            </ReactModal>
        </>
    )
}

export default Referral