import { psconnectionStart, psconnectionSuccess, psconnectionError } from '../features/web3/presaleSlice'
import Web3 from 'web3'
import info from '../info.json'
import { presaleAbi } from '../abi/presaleAbi'


const web3 = new Web3(info.readOnlyApi)
const presaleContract = new web3.eth.Contract(presaleAbi, info.contractAddress);


export const updatePresale = async (presale, dispatch) => {

    dispatch(psconnectionStart());
    try {
        const weiRaised = await presaleContract.methods.weiRaised().call();
        const rate = await presaleContract.methods.rate().call();
        const refPercent = await presaleContract.methods.referral().call();
        const tokenAddress = await presaleContract.methods.token().call();
        var refTrue;
        if (Number(refPercent) > 0) {
            refTrue = true;
        } else { refTrue = false }
        console.log(refTrue)
        const presaleInfo = {
            weiRaised: weiRaised,
            rate: rate,
            refpercent: refPercent,
            token: tokenAddress,
            refBool: refTrue,
        }
        dispatch(psconnectionSuccess(presaleInfo))
    } catch (error) {
        dispatch(psconnectionError());
        console.log(error)
    }
}