import React from 'react'

function Footer() {
    return (
        <>
            <div className='footer'>
                <div className="footercol1">
                    <a href='/'> <img src="./assets/logo.png" className='logoImg' /></a>
                </div>
                <div className="footercol2">

                </div>
                <div className="footercol3">

                </div>

            </div>
        </>

    )
}

export default Footer